// EUP variables
$eup-table-padding: 8px;
$eup-table-header-color: rgb(68, 159, 252);
$eup-table-caret-width: 8px;
$eup-table-caret-height: 12px;
$eup-table-caret-offset: 9px;

$tabletFullHeight: 88vh; /* 100vh doesn't include url, tabs, etc' */
$menuHeight: 52px;
$stickyHeaderHeight: 77px;
$tableHeaderHeight: 52px;
$tableLineHeight: 42px;
$settingPagesHeight: 750px;
$footerHeight: 37px;
$iPad100vhOffset: 64px;

$closeColor: rgb(172, 225, 245);
$hover-color: rgb(143, 215, 247);

$body-bg-light: #fff;

// override bootstrap variables
$gray-darker: rgb(133, 134, 137);
$gray-dark: rgb(160, 161, 164);
$gray: rgb(188, 188, 191);
$gray-light: rgb(216, 216, 218);
$gray-lighter: rgb(244, 244, 245);

$body-bg: $gray-darker;

$grid-gutter-width: 30px;
$grid-half-gutter: calc($grid-gutter-width / 2);

$input-color: #555;
$text-color: rgb(62, 61, 64);

$line-height-base: 1.428571429;

$table-bg: #fff;
$table-bg-accent: $gray-lighter;
$table-bg-hover: darken($gray-lighter, 5%);
$table-border-color: #ddd;
$table-cell-padding: $eup-table-padding $eup-table-padding $eup-table-padding 0;
$table-bg-active: rgb(201, 238, 254);

$btn-primary-color: rgb(12, 104, 170);
$btn-primary-border: rgb(68, 142, 191);
$btn-primary-bg: rgb(183, 231, 249);

$screen-sm: 1px; // ignore xs devices
$screen-md: 1024px;
$screen-md-min: $screen-md;
$screen-sm-max: ($screen-md-min - 1);
$laptop-sm: 670px;
$main-max-width: 1556px;

$breakpoint-sm: 1024px;
$breakpoint-md: 1350px;
$breakpoint-lg: 1080px;

$font-size-base: 14px;

//based on Lab Portal Style Guide

//grays palet
$lighter-gray: #f4f4f5;
$light-gray: #e3e3e3;
$medium-gray: #d0d1d2;
$dark-gray: #9fa1a3;
$black-gray: #3e3d40;

$primary-light: #bfe9fc;
$primary-color: #408dc1;
$primary-dark: #0067ac;
$primary-darker: #063b5f;

$error: #a94442;
$error-color: #e70000;

$message-padding: 10px 30px 10px 18px;
$message-margin: 15px -30px 0 -30px;
$message-border-left-width: 12px;
$message-warning-border-color: #ffcc02;
$message-warning-background-color: #fffae5;

$users-table-pending-delete: #f7e4d2;
$users-table-invitation-expired: #f7e4d2;
$users-table-invitation-active: #bcf2b4;
